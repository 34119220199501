@import "../../../styles/styles.scss";

.go-back-line-vertical {
  background: $ui-neutral;
  width: 1px;
  height: 28px;
}

.preview-nft-container {
  background-color: $ui-element-background;
  border-radius: 16px;

  .nft-info-container {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 65%;
    }

    .image-container {
      img {
        border-radius: 10px;
      }
    }

    .info-container {
      .name {
        color: $ui-main;
        font-size: 30px;
        font-weight: 700;
        line-height: 1;
      }

      .id-contract {
        .id-container {
          .id {
            background-color: $ui-main;
            color: white;
            font-weight: 700;
            font-size: 18px;
            padding: 8px;
            border-radius: 10px;
          }
          .warn {
            background-color: #FFAB00;
            color: black;
            font-weight: 600;
            font-size: 14px;
            border-radius: 10px;
          }
        }

      }
    }
  }

  .nft-metadata-container {
    width: 100%;
    max-height: 581px;
    overflow: auto;

    @include media-breakpoint-up(md) {
      width: 35%;
    }

    .metadata-text {
      word-break: break-word;
      font-family: 'Roboto';
      font-weight: 400;
    }
  }
}

@import "../../../styles/styles.scss";

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.nft-list {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(
      clamp(
        clamp(
          100%/ (3 + 1) + 0.1%,
          calc((768px - 100vw) * 1000),
          100%/ (2 + 1) + 0.1%
        ),
        calc((576px - 100vw) * 1000),
        100%
      ),
      1fr
    )
  );

  @include media-breakpoint-down(md) {
    grid-template-columns: repeat(
      auto-fill,
      minmax(
        clamp(
          clamp(
            100%/ (3 + 1) + 0.1%,
            calc((768px - 100vw) * 1000),
            50%/ (2 + 1) + 0.1%
          ),
          calc((576px - 100vw) * 1000),
          40%
        ),
        1fr
      )
    );
  }
  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(
      auto-fill,
      minmax(
        clamp(
          clamp(
            100%/ (5 + 1) + 0.1%,
            calc((1400px - 100vw) * 1000),
            100%/ (3 + 1) + 0.1%
          ),
          calc((991px - 100vw) * 1000),
          100%
        ),
        1fr
      )
    );
  }
  gap: 10px;
}

.card {
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  position: relative;
  transition: all 0.3s ease;
  border: 1px solid white;

  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }

  &.active {
    transition: all 0.3s ease;
    border: 1px solid $brand-primary;
  }

  .icon-container {
    position: absolute;
    right: 10px;
    top: 10px;

    .select-icon {
      display: flex;
      background-color: $ui-main;
      color: white;
      border-radius: 50%;
      width: 46px;
      height: 46px;
      flex-direction: row;
      align-items: flex-start;
      padding: 10px 10px 10px 11px;
      transition: all 0.3s ease;
      animation: unchecked 300ms linear;

      img {
        filter: brightness(0) invert(1);
      }
    }

    .selected-icon {
      display: flex;
      background-color: $brand-primary;
      color: white;
      border: 2px solid white;
      border-radius: 50%;
      width: 46px;
      height: 46px;
      flex-direction: row;
      align-items: flex-start;
      padding: 10px 10px 10px 11px;
      transition: all 0.3s ease;
      animation: checked 300ms linear;

      img {
        filter: brightness(0) invert(1);
      }
    }
  }

  img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 10px;

    @include media-breakpoint-up(md) {
      height: 100px;
    }

    @include media-breakpoint-up(lg) {
      height: 10vw;
    }
  }
}

@keyframes checked {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes unchecked {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}

.container {
  .asset-name {
    color: black;
    font-weight: bold;
  }

  .id-container {
    .id {
      background-color: $ui-main;
      color: white;
      font-weight: 700;
      font-size: 13px;
      border-radius: 10px;
    }

    .warn {
      background-color: #FFAB00;
      color: black;
      font-weight: 600;
      font-size: 12px;
      border-radius: 10px;
    }
  }
}

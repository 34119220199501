@import "../../../styles/styles.scss";

.nft-details-card {
  &-image {
    width: 120px;
    min-width: 120px;
    object-fit: cover;
    border-radius: 18px;
  }
}

@import '../../styles/styles.scss';

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar {
  width: 8px;
  height: 12px;
}
::-webkit-scrollbar-thumb {
  background: $ui-neutral;
  border-radius: 30px;
}
::-webkit-scrollbar-thumb:hover {
  background: $ui-border;
}
::-webkit-scrollbar-track {
  background: $ui-key;
  box-shadow: inset 0px 0px 0px 0px $ui-key;
  border-radius: 30px;
}

html,
body {
  background: $ui-main-background;
  color: $ui-main !important;
  min-height: 100vh;
}

#root {
  min-height: 100vh;
}

.layout-content {
  position: relative;
  z-index: 3;
  box-shadow: $dark-tile-shadow;
  min-height: 100vh;

  @include media-breakpoint-up(lg) {
    margin-left: 355px;
  }
}

.content-wrapper {
  display: flex;
  justify-content: center;
  padding: 0 24px 0 24px;
  padding-top: 72px;

  @include media-breakpoint-up(md) {
    padding-top: 90px;
    padding-bottom: 44px;
  }

  @include media-breakpoint-up(lg) {
    padding-top: 98px;
  }

  .content {
    width: 100%;
  }
}

.network-switch-screen {
  height: 100vw;

  .icon > img {
    width: 78px !important;
    height: 78px !important;
  }

  @include media-breakpoint-up(md) {
    height: 80vw;
  }

  @include media-breakpoint-up(lg) {
    height: 50vw;
  }
}

// Typography
.text-bold {
  font-weight: 700;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-main {
  color: $ui-main !important;
}

.text-primary {
  color: $brand-primary !important;
}

.text-secondary {
  color: $ui-secondary !important;
}

.text-solid {
  color: $ui-solid !important;
}

.text-border {
  color: $ui-border;
}

.text-danger {
  color: $attention-alarm;
}

.space-btw {
  justify-content: space-between;
}

.text-huge {
  font-size: 38px;
  line-height: 1.5;

  @include media-breakpoint-up(md) {
    font-size: 34px;
    line-height: 1.4;
  }

  @include media-breakpoint-up(lg) {
    font-size: 30px;
    line-height: 1.4;
  }
}

.text-large {
  font-size: 30px;
  line-height: 1.5;

  @include media-breakpoint-up(md) {
    font-size: 28px;
    line-height: 1.4;
  }

  @include media-breakpoint-up(lg) {
    font-size: 24px;
    line-height: 1.4;
  }
}

.text-medium {
  font-size: 24px;
  line-height: 1.5;

  @include media-breakpoint-up(md) {
    font-size: 22;
    line-height: 1.4;
  }

  @include media-breakpoint-up(lg) {
    font-size: 20px;
    line-height: 1.4;
  }
}

.text-normal {
  font-size: 16px;
  line-height: 1.5;
}

.text-small {
  font-size: 14px;
  line-height: 1.5;
}

.text-tiny {
  font-size: 13px;
  line-height: 1.5;
}

.text-decoration-none {
  text-decoration: none;
}

.text-align-justify {
  text-align: justify;
}

.font-weight-700 {
  font-weight: 700;
}

.ws-no-wrap {
  white-space: nowrap;
}

.link-primary {
  color: $brand-primary;
  text-decoration: none;

  &:hover {
    color: $brand-hover;
    text-decoration: none;
  }
}

// Flex helpers
.flex-one {
  flex: 1;
}

.basis-col {
  flex-basis: 50%;
}

.flex-col {
  flex: 1 0 0%;
}

.btn {
  transition: all 0.15s linear;
}

.height-100 {
  height: 100%;
}

.width-100 {
  width: 100%;
}

.width-auto {
  width: auto;
}

@include media-breakpoint-up(sm) {
  .width-sm-auto {
    width: auto;
  }

  .width-sm-80 {
    width: 80%;
  }

  .width-sm-100 {
    width: 100%;
  }
}

@include media-breakpoint-up(md) {
  .width-md-50 {
    width: 50%;
  }

  .width-md-auto {
    width: auto;
  }
}

@include media-breakpoint-up(lg) {
  .width-lg-fixed-450 {
    width: 450px;
  }
}

.max-width-100 {
  max-width: 100%;
}

.rotate-0 {
  transform: rotate(0deg);
}

.rotate-90 {
  transform: rotate(90deg);
}

.rotate-180 {
  transform: rotate(180deg);
}

@include media-breakpoint-up(lg) {
  .rotate-lg-0 {
    transform: rotate(0deg);
  }
}

.line {
  border-top: 1px solid $ui-neutral;
}

.line-vertical {
  border-left: 1px solid $ui-neutral;
}

.z-index-6 {
  z-index: 6;
}

.z-index-7 {
  z-index: 7;
}

.pulse {
  animation-name: pulse;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  transition: 0.2s ease-in-out;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.65;
  }
  100% {
    opacity: 1;
  }
}

.target-network-dropdown-list {
  .container-select-dropdown-list {
    height: 280px;
    overflow-y: scroll;
  }
}

.alarm-disabled {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer;
}

.fade-in-400 {
  animation: fadeIn 400ms;
  -webkit-animation: fadeIn 400ms;
  -moz-animation: fadeIn 400ms;
  -o-animation: fadeIn 400ms;
  -ms-animation: fadeIn 400ms;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

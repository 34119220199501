@import "../../../styles/styles.scss";

.class-success {
  svg {
    height: 100%;
    margin-top: 70px;
    width: 95%;
  }
}

.success-text-wrapper {
  width: 60%;
  text-align: center;
}

@import "../../../../../../styles/styles.scss";
.nft-card-listing-image {
  width: 75px;
  min-width: 75px;
  height: 50px;
  object-fit: cover;
  border-radius: 12px;
}

.nft-dsc-wrapper {
  max-width: 158px;
  .nft-dsc {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.target-network-column {
  position: absolute;
  right: 26%;
}

.flex-nft-images {
  min-width: 27%;
}
.networks-nft {
  min-width: 40%;
}
.nft-button {
  min-width: 12%;
}

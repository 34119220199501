@import "../../../styles/styles.scss";

.nft-claim-page-container {
  .data-wrapper {
    .nft-claimed-option-wrapper {
      .nft-claimed-wrapper {
        .nft-claimed-number {
          background-color: $ui-menu;
          color: white;
          width: 54px;
          height: 37px;
          border-radius: 50px;
        }
      }
      @include media-breakpoint-down(md) {
        flex-direction: column;
        .nft-claimed-wrapper {
          justify-content: space-between;
          .nft-claimed-number {
            width: 50%;
          }
          .nft-claimed-title {
            text-align: center;
            width: 50%;
          }
        }
      }
    }
  }
}

@import "../../../styles/styles.scss";

.skeleton {
  background-color: $ui-secondary;
  border-radius: 6px;
  animation-name: pulse;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  transition: 0.2s ease-in-out;

  &.circle {
    border-radius: 50%;
  }

  &.rounded {
    border-radius: 16px;
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.65;
  }
  100% {
    opacity: 1;
  }
}

@import "../../../../../../styles/styles.scss";

.container-nft-header {
  position: relative;
  height: 2.5vh;
  .nft-token {
    position: absolute;
    left: 2%;
  }
  .nft-schema {
    position: absolute;
    left: 32%;
  }
  .source-chain {
    position: absolute;
    left: 45%;
  }
  .target-network {
    position: absolute;
    left: 62%;
    min-width: 90px;
  }
  .amount-nft {
    position: absolute;
    left: 79%;
  }
  .status-header {
    position: absolute;
    right: 2vw;
  }
}

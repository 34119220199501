@import "../../../styles/styles.scss";

.nft-image-wrap {
  background-color: $ui-element-background;
  padding: 24px 16px;
  box-sizing: border-box;
  border-radius: 16px;
  border: 1px solid $ui-web-background;

  flex-basis: 100px;

  .nft-image {
    width: 100%;
    object-fit: cover;
    border-radius: 18px;

    @include media-breakpoint-up(sm) {
      width: 120px;
      min-width: 120px;
    }
  }
}
.est-transaction {
  background-color: $ui-element-background;
  border-radius: 16px;
  border: 1px solid $ui-web-background;
}
.nft-card-title {
  .icon {
    width: 30px;
    height: 30px;
    .mask {
      width: 32px !important;
      height: 32px !important;
    }
  }
  .arrow {
    background: $ui-border;
    width: 1px;
    height: 32px;
  }
}

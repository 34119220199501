@import "../../../../../styles/styles.scss";

.claimed-number {
  background-color: $ui-menu;
  color: white;
  width: 54px;
  height: 37px;
  border-radius: 50px;
}

.entryTableWrapper {
  position: relative;
  .info {
    left: 3.5vw;
    position: absolute;
    .from-icon-wrapper {
      .logo {
        width: 20px;
        svg {
          max-width: 21px;
          max-height: 21px;
          width: 100%;
          height: 100%;
        }
      }
    }
    .target-title {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }
  .amount-wrapper {
    position: absolute;
    left: 55%;
  }

  .main-target {
    .to-icon-wrapper {
      .logo {
        svg {
          max-width: 21px;
          max-height: 21px;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .arrow-icon {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
  .info {
    min-width: 46%;
  }
  .token-amount-wrapper {
    min-width: 60px;
    text-align: right;
  }

  .claim-status {
    min-width: 21%;
  }

  button {
    width: auto;
  }
}

.pointer {
  cursor: pointer;
}

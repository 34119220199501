@import "../../../../../../styles/styles.scss";
.mobile-nft-row-container {
  // closed row
  .closed-row-wrapper {
    .nft-images {
      min-width: 40%;
    }
    .nft-amount-status-wrap {
      min-width: 30%;
    }
    .nft-mobile-images-wrapper {
      .nft-mobile-listing-image {
        object-fit: cover;
        border-radius: 12px;
        width: 52px;
        height: 36.19px;
      }
    }
    .logo {
      svg {
        max-width: 21px;
        max-height: 21px;
        width: 100%;
        height: 100%;
      }
    }
    .amount-wrapper {
      .token span {
        margin-left: 5px;
      }
    }
  }

  // opened row
  .opened-row-wrapper {
    border: 1px solid $ui-border;
    box-shadow: var(--selectedWindowShadow);
    border-radius: 16px;
    .nft-mobile-listing-image {
      object-fit: cover;
      border-radius: 12px;
      width: 52px;
      height: 36.19px;
    }
    .logo {
      width: 20px;
      svg {
        max-width: 21px;
        max-height: 21px;
        width: 100%;
        height: 100%;
      }
    }
    .source-data-wrapper {
      box-shadow: var(--selectedWindowShadow);
      .source-target-chain-data {
        .logo {
          width: 20px;
        }
      }
      .amount-data-container {
        .token-amount-wrapper {
          span {
            margin-right: 5px;
          }
          div .icon {
            margin-right: 5px;
          }
        }
      }
    }
    .button-container {
      border-radius: 12px;
      box-shadow: var(--selectedWindowShadow);
      .button-box {
        width: 100%;
        a {
          width: 100%;
          button {
            width: 100%;
          }
        }
      }
    }
  }
}

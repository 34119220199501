@import "../../../styles/styles.scss";

.skeleton-nft-list {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(
      clamp(
        clamp(
          100%/ (3 + 1) + 0.1%,
          calc((768px - 100vw) * 1000),
          100%/ (2 + 1) + 0.1%
        ),
        calc((576px - 100vw) * 1000),
        100%
      ),
      1fr
    )
  );

  @include media-breakpoint-down(md) {
    grid-template-columns: repeat(
      auto-fill,
      minmax(
        clamp(
          clamp(
            100%/ (3 + 1) + 0.1%,
            calc((768px - 100vw) * 1000),
            50%/ (2 + 1) + 0.1%
          ),
          calc((576px - 100vw) * 1000),
          40%
        ),
        1fr
      )
    );
  }
  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(
      auto-fill,
      minmax(
        clamp(
          clamp(
            100%/ (5 + 1) + 0.1%,
            calc((1400px - 100vw) * 1000),
            100%/ (3 + 1) + 0.1%
          ),
          calc((991px - 100vw) * 1000),
          100%
        ),
        1fr
      )
    );
  }
  gap: 10px;
}

.skeleton-nft-card {
  position: relative;
  background-color: white;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
}

.skeleton-nft-icon {
  position: absolute;
  border: 2px solid white;
  top: 10px;
  right: 10px;
}

.skeleton-nft-image {
  background-color: $ui-secondary;
  border-radius: 10px;
  width: 100%;
  height: 60%;
}

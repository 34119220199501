@import "../../../styles/styles.scss";

.add-nft-container {
  background-color: $ui-element-background;
  border-radius: 16px;

  .collection-address {
    z-index: 2;
    background-color: white;

    ::placeholder {
      color: $ui-border;
    }

    :-ms-input-placeholder {
      color: $ui-border;
    }

    ::-ms-input-placeholder {
      color: $ui-border;
    }

    .arrow {
      transition: 0.1s all ease-in-out;
    }
  }

  .token-id {
    background-color: white;

    ::placeholder {
      color: $ui-border;
    }

    :-ms-input-placeholder {
      color: $ui-border;
    }

    ::-ms-input-placeholder {
      color: $ui-border;
    }
  }
}

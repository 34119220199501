@import "../../../styles/styles.scss";

.nft-card {
  &-image {
    width: 100%;
    object-fit: cover;
    border-radius: 18px;

    @include media-breakpoint-up(sm) {
      width: 175px;
      min-width: 175px;
    }
  }
}

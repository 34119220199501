@import "../../../styles/styles.scss";

.search-input {
  ::placeholder {
    color: $ui-border;
  }

  :-ms-input-placeholder {
    color: $ui-border;
  }

  ::-ms-input-placeholder {
    color: $ui-border;
  }
}

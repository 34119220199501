@import "../../../../../styles/styles.scss";

$breakpoint-tablet: 1066px;
$min-breakpoint-tablet: 991px;
.nft-claim-listing {
  .container-header {
    box-shadow: none;
    padding: 0 25px;
  }
  .nft-claim-wrappers {
    background-color: --uiElementBackground;
    border-radius: 16px;

    border: 1px solid $ui-web-background;
    svg {
      height: 100%;
      width: 100%;
    }
    .nft-claim-table {
      min-height: 20vh;
      .icon-wrapper {
        background: $ui-key;
        min-width: 36px;
        height: 36px;
        border-radius: 11px;
      }
    }
  }
}

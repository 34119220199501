@import "../../../styles/styles.scss";

.nft-page-container {
  .nft-data-wrapper {
    .nft-claimed-option-wrapper {
      .text-field {
        .icon {
          cursor: pointer;
        }
      }

      .claimed-wrapper {
        .claimed-number {
          background-color: #484a57;
          color: white;
          width: 54px;
          height: 37px;
          border-radius: 50px;
          margin-left: 15px;
        }
      }
      @include media-breakpoint-down(md) {
        flex-direction: column;
        .claimed-wrapper {
          justify-content: space-between;
          .claimed-number {
            width: 50%;
          }
          .claimed-title {
            text-align: center;
            width: 50%;
          }
        }
      }
    }
  }
  .step-nft-buttons {
    bottom: 20px;
    background-color: white;

    @include media-breakpoint-down(md) {
      padding: 5px 5px 0px 0px;
      position: sticky;
      bottom: 0;
    }
  }
}
@include media-breakpoint-down(md) {
  .component-select {
    .container-select-wrapper {
      padding: 12px 50px;
    }

    width: 100%;
    .container-select-wrapper {
      width: 100%;
    }
  }
}
.nft-mobile-search {
  .claimed-option-wrapper {
    width: 100%;
    .dropdown-option-wrapper {
      width: 100%;
    }
  }
}
@include media-breakpoint-down(md) {
  .dropdown-option-wrapper {
    flex-direction: column;
  }
}

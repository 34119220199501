@import "../../../../styles/styles.scss";

.autoclaiming {
  .label-button {
    padding-left: 0px;
  }

  .label-button-label {
    margin-right: 0px;
  }
}

.btn[disabled] {
  border-color: $ui-border !important;
  color: $ui-secondary !important;
  cursor: not-allowed !important;
  background-color: white !important;
  pointer-events: none !important;

  .icon .mask {
    background-color: $ui-secondary !important;
    cursor: not-allowed;
  }
}

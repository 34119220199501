@import "../../../styles/styles.scss";

.modal-claim {
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(109, 116, 176, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  pointer-events: none;

  &.enter-done {
    opacity: 1;
    pointer-events: visible;
    .modal-claim-content {
      transform: translateY(0);
    }
  }
  &.modal-claim.exit {
    opacity: 0;
    .modal-content {
      transform: translateY(-200px);
    }
  }

  .modal-claim-content {
    max-width: 542px;
    width: 100%;
    padding: 50px 70px 100px;
    background: #fff;
    box-shadow: 0px 8px 35px rgba(204, 209, 255, 65%);
    border-radius: 14px;
    display: flex;
    align-items: center;
    justify-content: center;

    .modal-claim-header {
      padding: 10px;
      .modal-claim-title {
        margin: 0;
      }
    }

    .modal-claim-body {
      padding: 10px;
      border-top: 1px solid #eee;
      border-bottom: 1px solid #eee;
    }
    .modal-claim-footer {
      padding: 10px;
    }
  }
}

@mixin modal-success {
  .modal-success {
    .label {
      color: #b3b8d7;
      font-size: 12px;
      color: #9e9eeb;
      margin-bottom: 7px;
      color: #b3b8d7;
    }

    .value {
      font-weight: 500;
      font-size: 13px;
      line-height: 15px;
      color: #6d74b0;
    }
    .link {
      cursor: pointer;
      text-decoration-line: underline;
    }
    a {
      color: #5466fd;
    }

    h2 {
      margin-top: 15px;
      font-weight: 500;
      font-size: 28px;
      line-height: 33px;
      /* identical to box height */

      color: #5466fd;
    }

    .token-data {
      margin-top: 30px;
      border-top: 1px solid #f3f4ff;
      padding: 0 15px;
      padding-top: 15px;

      & > * + * {
        margin-left: 8px;
      }
    }

    .recepient {
      margin-top: 10px;
      padding: 0 15px;
      padding-bottom: 15px;
      border-bottom: 1px solid #f3f4ff;
    }

    .transaction-url {
      margin-top: 15px;
      padding: 0 15px;
    }

    .transaction-next-step {
      margin-top: 10px;
      padding: 15px 15px 0;
      border-top: 1px solid #f3f4ff;
    }
  }
}

.modal-container {
  position: fixed;
  max-width: 542px;
  width: 100%;
  padding: 50px 70px 100px;
  background: #fff;
  box-shadow: 0px 8px 35px rgba(204, 209, 255, 0.65);
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  @include modal-success;

  @media (max-width: 640px) {
    padding: 35px 30px 45px;

    .modal-success {
      h2 {
        text-align: center;
      }
    }
  }

  .close {
    position: absolute;
    right: 30px;
    top: 30px;
  }
}
